import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

const TOKEN_KEY = 'user-access-token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<any>;
  private authState = new BehaviorSubject(null);

  constructor(
    private storage: Storage,
    private router: Router
  ) {
    this.loadUser();
    this.user = this.authState.asObservable().pipe(
      filter(response => response)
    );
  }

  loadUser() {
    this.storage.create()
    this.storage.get(TOKEN_KEY).then(data => {
      // console.log('loaded user', data)
        if(data) {
          this.authState.next(data);
        } else {
          this.authState.next({email: null, role: null});
        }
    });
  }

  signIn(credentials): Observable<any> {

    let email = credentials.email;
    let pwd = credentials.pwd;
    let role = credentials.role;
    let user = null;

    user = { email, role }

    this.authState.next(user);
    this.storage.create();
    this.storage.set(TOKEN_KEY, user);

    return of(user);
  }

  async signOut() {
    await this.storage.set(TOKEN_KEY, null);
    this.authState.next(null);
    this.router.navigateByUrl('/login');
  }
}
