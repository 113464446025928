import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private alertCtrl: AlertController
  ) { }
  canActivate(route: ActivatedRouteSnapshot) {

    const expectedRole = route.data.role;
    // console.log('expected: ', expectedRole)

    return this.auth.user.pipe(
      take(1),
      map(user => {

        // console.log('log: ', user)
        if (user) {

          let role = user['role'];

          if (role.includes(expectedRole)) {
            return true;
          } else {
            this.showAlert();
            return this.router.parseUrl('/login');
          }

        } else {
          this.showAlert();
          return this.router.parseUrl('/login');
        }
      })
    );

  }


  async showAlert() {
    let alert = await this.alertCtrl.create({
      header: "Não Autorizado",
      message: "Você não está autorizado para acessar essa página",
      buttons: ['OK']
    });

    alert.present();
  }
}
