import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ACOMPANHAR'
    }
  },
  {
    path: 'buscar-pre-nota',
    loadChildren: () => import('./pages/buscar-pre-nota/buscar-pre-nota.module').then( m => m.BuscarPreNotaPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'BUSCAR'
    }
  },
  {
    path: 'almoxarifado',
    loadChildren: () => import('./pages/almoxarifado/almoxarifado.module').then( m => m.AlmoxarifadoPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ALMOX'
    }
  },
  {
    path: 'compras',
    loadChildren: () => import('./pages/compras/compras.module').then( m => m.ComprasPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'COMPRAS'
    }
  },
  {
    path: 'fiscal',
    loadChildren: () => import('./pages/fiscal/fiscal.module').then( m => m.FiscalPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'FISCAL'
    }
  },
  {
    path: 'criar-usuario',
    loadChildren: () => import('./pages/criar-usuario/criar-usuario.module').then( m => m.CriarUsuarioPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'USER'
    }
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
